<div class="border-round-lg border-2 border-gray-400 calendar-container">
    <div class="flex justify-content-between align-items-center pb-2 pt-2">
        <p-button icon="pi pi-chevron-left left-arrow" label="{{shortMonthNames[(currentMonth + 11) % 12]}}" [link]="true"
        styleClass="outline-none other-month" (click)="changeMonth(-1)"></p-button>
       <div class="calendar-day font-semibold current-month">{{ monthNames[currentMonth] }}, {{ currentYear }}</div>
        <p-button icon="pi pi-chevron-right right-arrow" label="{{shortMonthNames[(currentMonth + 1) % 12] }}" [link]="true"
        styleClass="other-month outline-none" (click)="changeMonth(1)" iconPos="right"></p-button>
    </div>

    <div class="flex flex-wrap mt-2 border">
        <div class="primary-text basis-7-cols flex holiday">Sun</div>
        <div class="primary-text basis-7-cols flex">Mon</div>
        <div class="primary-text basis-7-cols flex">Tue</div>
        <div class="primary-text basis-7-cols flex">Wed</div>
        <div class="primary-text basis-7-cols flex">Thu</div>
        <div class="primary-text basis-7-cols flex">Fri</div>
        <div class="primary-text basis-7-cols flex holiday">Sat</div>
        <div *ngFor="let day of monthDays"
             (click)="selectDate(day)"
             class="flex border-top-1 border-gray-400 calendar-day basis-7-cols cursor-pointer">
          <div class="flex flex-column" style="min-width: 33px !important; min-height: 33px !important">
            <span class="flex border-circle p-2"
            [class.bg-free]="day?.free && day?.date !== selectedDate"
            [class.text-white]="day?.free && day?.date !== selectedDate"
            [class.selected-border]="day?.date && day?.date === selectedDate"
            [class.opacity-50]="!day?.date"
            [class.holiday]="day?.isUnavailable">{{ day?.date ? +day.date.split('-')[2] : '' }}</span>
            <span class="p-1" [class.today]="day?.date === today"></span>
            <div class="flex justify-content-between p-1">
              <span *ngIf="day?.amAvailable" class="p-1 border-circle am-available"></span>
              <span *ngIf="day?.pmAvailable" class="p-1 border-circle pm-available"></span>
            </div>
          </div>
        </div>
      </div>
</div>

<div class="border-round-lg border-2 mt-3 border-gray-400 calendar-container">
  <div class="flex flex-wrap justify-content-between align-items-center gap-2 p-2">
    <div class="flex gap-2 pl-3 align-items-center">
      <div class="w-2rem h-2rem flex-shrink-0 bg-free border-circle"></div>
      <span>Free</span>
    </div>
    <div class="flex gap-2 pl-3 align-items-center">
      <div class="w-2rem flex-shrink-0 bg-today border-1" style="height:6px;"></div>
      <span>Today</span>
    </div>
    <div class="flex gap-2 pl-3 align-items-center">
      <div class="w-2rem h-2rem flex-shrink-0 bg-selected selected-border"></div>
      <span>Selected</span>
    </div>
    <div class="flex gap-2 pl-3 align-items-center">
      <div class="w-2rem h-2rem flex-shrink-0 bg-am-available border-circle"></div>
      <span>AM times available</span>
    </div>
    <div class="flex gap-2 pl-3 align-items-center">
      <div class="w-2rem h-2rem flex-shrink-0 bg-pm-available border-circle"></div>
      <span>PM times available</span>
    </div>
  </div>
</div>