import { Component, inject, OnDestroy } from "@angular/core"
import { filter, Subscription } from "rxjs"
import { TitleService } from "../services/title.service"
import { NavigationEnd, Router } from "@angular/router"
import { OrderRoutes } from "../../features/orders/orders-routes.enum"
import { FormValidationService } from "../services/form/form-validation.service"
import { OrderStateService } from "../store/state/order/order.state.service"

@Component({
    template: ''
})
export abstract class BaseComponent implements OnDestroy {

    protected subscriptions = new Subscription()
    protected titleService: TitleService = inject(TitleService)
    formValidationService = inject(FormValidationService)
    orderStateService = inject(OrderStateService)
    protected router: Router = inject(Router)
    protected readonly orderRoutes = OrderRoutes

    /**
     *
     */
    constructor() {
        this.subscriptions.add(this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => {
                this.setTitle(undefined)
            })
        )
    }
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    setTitle(title: string, subTitle?: string) {
        this.titleService.update({ title: title, subTitle: subTitle })
    }
}