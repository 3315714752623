import { MenuItem } from 'primeng/api'
export const AppMenu: MenuItem[] = [
    //orders
    {
        label: 'Orders', routerLink: ['order/setup'], icon: 'pi pi-book', id: '1', state: {
            userModules: ['orderentry']
        }
    },
    //analytics
    {
        label: 'Analytics', icon: 'pi pi-chart-line', id: '2', state: {
            userModules: ['analytics']
        },
        items: [
            { label: 'Sales Dashboard', routerLink: ['analytics/salesDashboard'], id: '2-1' },
            { label: 'Install Dashboard', routerLink: ['analytics/installDashboard'], id: '2-2' },
            { label: 'Wireless Dashboard', routerLink: ['analytics/wirelessDashboard'], id: '2-3' },
            {
                label: 'Detail Reports', id: '2-4',
                items: [
                    { label: 'Sales Order History', routerLink: ['analytics/detailReports/salesOrderHistory'], id: '2-4-1' },
                    { label: 'Pending Orders', routerLink: ['analytics/detailReports/pendingOrders'], id: '2-4-2' },
                    { label: 'Wsc', routerLink: ['analytics/detailReports/wsc'], id: '2-4-3' },
                    { label: 'Leads', routerLink: ['analytics/detailReports/leads'], id: '2-4-4' },
                    { label: 'Contact Follow Ups', routerLink: ['analytics/detailReports/contactFollowUps'], id: '2-4-5' },
                    { label: 'Activities', routerLink: ['analytics/detailReports/activities'], id: '2-4-6' }
                ]
            }
        ]
    },
    //customers
    {
        label: 'Customers', routerLink: ['customers'], icon: 'pi pi-users', id: '3', state: {
            userModules: ['customers']
        }
    },
    //schedule
    {
        label: 'Schedule', icon: 'pi pi-calendar-times', id: '4', state: {
            userModules: ['schedule']
        },
        items: [
            { label: 'Schedule Dashboard', routerLink: ['schedule/scheduleDashboard'], id: '4-1' },
            { label: 'Schedule Jobs', routerLink: ['schedule/scheduleJobs'], id: '4-2' },
            { label: 'Installer Report', routerLink: ['schedule/installerReport'], id: '4-3' }
        ]
    },
    //inventory
    {
        label: 'Inventory', routerLink: ['inventory'], icon: 'pi pi-users', id: '5', state: {
            userModules: ['inventory']
        },
        items: [
            {
                label: 'Inventory Reports', id: '5-1',
                items: [
                    { label: 'Equipment Aging Report', routerLink: ['inventory/inventoryReports/equipmentAgingReport'], id: '5-1-1' },
                    { label: 'Inventory Locator', routerLink: ['inventory/inventoryReports/inventoryLocator'], id: '5-1-2' }
                ]
            },
            { label: 'Inventory Management', routerLink: ['inventory/inventoryManagement'], id: '5-2' },
            { label: 'Add Inventory', routerLink: ['inventory/addInventory'], id: '5-3' },
            { label: 'Transfer Inventory', routerLink: ['inventory/transferInventory'], id: '5-4' },
            { label: 'Location Management', routerLink: ['inventory/locationManagement'], id: '5-5' },
            { label: 'Place Purchase order', routerLink: ['inventory/placePurchaseOrder'], id: '5-6' }

        ]
    },
    //financial
    {
        label: 'Financial', routerLink: ['financial'], icon: 'pi pi-dollar', id: '6', state: {
            userModules: ['financial']
        },
        items: [
            {
                label: 'Revenue', id: '6-1',
                items: [
                    { label: 'Ppc', routerLink: ['financial/ppc'], id: '6-1-1' },
                    { label: 'Csf', routerLink: ['financial/csf'], id: '6-1-2' },
                    { label: 'Probation', routerLink: ['financial/probation'], id: '6-1-3' },
                    { label: 'Ppc Unpaid', routerLink: ['financial/ppcUnpaid'], id: '6-1-4' },
                    { label: 'Bundle Unpaid', routerLink: ['financial/bundleUnpaid'], id: '6-1-5' },
                    { label: 'Viasat Pay', routerLink: ['financial/viasatPay'], id: '6-1-6' },
                    { label: 'Nffl', routerLink: ['financial/nffl'], id: '6-1-7' }
                ]
            },
            {
                label: 'Payroll', id: '6-2',
                items: [
                    { label: 'Pay Report', routerLink: ['financial/payroll/payReport'], id: '6-2-1' },
                    { label: 'Time Clock', routerLink: ['financial/payroll/timeClock'], id: '6-2-2' },
                    {
                        label: 'Payroll Admin', id: '6-2-3',
                        items: [
                            { label: 'Individual Pay', routerLink: ['financial/payroll/payrollAdmin/individualPay'], id: '6-2-3-1' },
                            { label: 'Pay Groups', routerLink: ['financial/payroll/payrollAdmin/payGroups'], id: '6-2-3-2' }
                        ]
                    },
                ]
            },
            {
                label: 'Invoicing', id: '6-3',
                items: [
                    { label: 'Invoice Item Management', routerLink: ['financial/invoicing/invoiceItemManagement'], id: '6-3-1' },
                    { label: 'Reports', routerLink: ['financial/invoicing/reports'], id: '6-3-2' },
                    { label: 'Customer Invoice History', routerLink: ['financial/invoicing/customerInvoiceHistory'], id: '6-3-3' }
                ]
            },

        ]
    },
    //admin
    {
        label: 'Admin', icon: 'pi pi-cog', id: '7', state: {
            userModules: ['admin']
        },
        items: [
            { label: 'My Profile', routerLink: ['admin/myProfile'], id: '7-1' },
            { label: 'User Management', routerLink: ['admin/userManagement'], id: '7-2' },
            {
                label: 'Dealer Config', id: '7-3',
                items: [
                    { label: 'Dealer Data', routerLink: ['admin/dealerConfig/dealerData'], id: '7-3-1' },
                    { label: 'User Settings', routerLink: ['admin/dealerConfig/userSettings'], id: '7-3-2' },
                    { label: 'Sara Settings', routerLink: ['admin/dealerConfig/saraSettings'], id: '7-3-3' },
                    { label: 'Account Settings', routerLink: ['admin/dealerConfig/accountSettings'], id: '7-3-4' },
                    { label: 'Merchant Settings', routerLink: ['admin/dealerConfig/merchantSettings'], id: '7-3-5' }
                ]
            },
            { label: 'Notification Manager', routerLink: ['admin/notificationManager'], id: '7-4' },
            {
                label: 'API Admin', id: '7-5',
                items: [
                    { label: 'Premium Access', routerLink: ['admin/apiAdmin/premiumAccess'], id: '7-5-1' },
                    { label: 'API Access', routerLink: ['admin/apiAdmin/apiAccess'], id: '7-5-2' }
                ]
            },
        ]
    },
    //support
    {
        label: 'Support', icon: 'pi pi-phone', id: '8', state: {
            userModules: ['support']
        },
        items: [
            { label: 'Support Items', routerLink: ['support/supportItems'], id: '8-1' },
            { label: 'FAQ', routerLink: ['support/faq'], id: '8-2' },
            { label: 'Phone Numbers', routerLink: ['support/phoneNumbers'], id: '8-3' },
            { label: 'Contact Us', routerLink: ['support/contactUs'], id: '8-4' }
        ]
    },
    {
        label: 'Settings', icon: 'pi pi-cog', id: '9',
        items: [
            { label: 'My Profile', routerLink: ['user/profileSetting'], id: '9-1' },
            {
                label: 'Time Entry', routerLink: ['user/timeentry'], id: '9-2', state: {
                    userModules: ['CheckInCheckOutEntry']
                }
            }
        ]

    },
    {
        label: 'Live support', icon: 'pi pi-live-support', id: '99' // This has to be the max number because of it's positioning in the menu

    },
]