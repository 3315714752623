<div class="flex flex-grow-1 justify-content-center mt-5 border-round-lg h-3rem align-items-center header-bg-color text-white">
    Installation calendar
 </div>
<div class="py-4 w-full">
    <app-calendar [availableDates]="availableDates()" (dateSelected)="onDateSelected($event)"></app-calendar>
    <div  *ngIf="selectedDate" class="border-round-lg border-2 mt-3 border-gray-400 slot-container">
        <div class="flex flex-column justify-content-between align-items-center gap-2 p-4">
            <div class="text-lg font-semibold">Available Time Slots:</div>
            <span class="font-light">{{ selectedDate.date | date }}</span>
            <div class="flex flex-wrap justify-content-center gap-4 mt-2">
                @for (item of selectedDate.slots; track $index) {
                    <p-button [styleClass]="'button-secondary border-round-md px-5 py-2'"
                    label={{item.slot}}  raised="true" severity="info" (onClick)="onSlotSelected(item)"
                    styleClass="text-lg pl-0"></p-button>
                } @empty {
                    No slots available
                }
            </div>
         </div>
    </div>
</div>