<div class="sidebar md:flex flex-column surface-900 z-5 hidden fixed" #rootmenu>
    <div class="flex-grow-1">
        <ul class="flex-column list-none px-0 m-0 hidden md:flex mt-7 sm:mt-0" style="height: 92vh;">
            <li [ngClass]="{'mb-1': item.visible !== false , 'mt-auto': item.id === '99' }" *ngFor="let item of items; let i = index;">
                <app-menu-item [item]="item" [index]="i" [root]="true" [isCollapsed]="isCollapsed"></app-menu-item>
            </li>
        </ul>
    </div>
    <div class="absolute left-0 min-h-screen min-w-full top-0 cursor-pointer" (click)="$event.preventDefault()" [hidden]="!isCollapsed || deviceType !== 'Web'">
<!-- This is place holder for menu to be clicked on ipad devices -->
    </div>
</div>