import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimeNGModule } from '../../modules/primeng.module';

@Component({
  selector: 'app-calendar',
  standalone: true,
  imports: [CommonModule, PrimeNGModule],
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit{
  @Input() availableDates: any = [];
  @Output() dateSelected = new EventEmitter<string>();
  selectedDate: string | null = null;
  today: string = new Date().toISOString().split('T')[0];
  monthDays: any[] = [];
  currentYear: number = new Date().getFullYear();
  currentMonth: number = new Date().getMonth();
  monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  shortMonthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  ngOnInit() {
    this.generateMonthDays();
  }

  generateMonthDays() {
    const firstDay = new Date(this.currentYear, this.currentMonth, 1).getDay();
    const totalDays = new Date(this.currentYear, this.currentMonth + 1, 0).getDate();
    
    this.monthDays = Array.from({ length: firstDay + totalDays }, (_, i) => {
      if (i < firstDay) {
        return { date: null };
      }
      const dateObj = new Date(this.currentYear, this.currentMonth, i - firstDay + 1);
      const formattedDate = `${this.currentYear}-${(this.currentMonth + 1).toString().padStart(2, '0')}-${(i - firstDay + 1).toString().padStart(2, '0')}`;
      const available = this.availableDates.find(d => d.date === formattedDate);
      return {
        date: formattedDate,
        free: available?.free || false,
        amAvailable: available?.amAvailable || false,
        pmAvailable: available?.pmAvailable || false,
        isUnavailable: formattedDate < this.today || dateObj.getDay() === 0 || dateObj.getDay() === 6,
        slots: available?.slots || []
      };
    });
  }

  changeMonth(direction: number) {
    this.currentMonth += direction;
    if (this.currentMonth < 0) {
      this.currentMonth = 11;
      this.currentYear--;
    } else if (this.currentMonth > 11) {
      this.currentMonth = 0;
      this.currentYear++;
    }
    this.generateMonthDays();
  }

  selectDate(day) {
    if (!day?.date || day?.isUnavailable) return;
    this.selectedDate = day.date;
    this.dateSelected.emit(day);
  }
}