import { inject, Injectable } from '@angular/core'
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject, Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { AppStateService } from '../store/state/app/app.state.service';
import { EncryptDecryptService } from './encrypt-decrypt.service';



@Injectable({
    providedIn: 'root'
})
export class SignalRHubService {
    private hubConnection: signalR.HubConnection;
    private environmentService: EnvironmentService = inject(EnvironmentService);
    #appStateService = inject(AppStateService)
    #signalREventService = inject(SignalREventService)
    #encryptDecryptService: EncryptDecryptService = inject(EncryptDecryptService);

    private hubUrl: string;
    constructor() {
        const sessionId = this.#appStateService.getSession()?.SessionID
        const headers: signalR.MessageHeaders = { 'X-SARAPlus-ID': this.environmentService.env.azureFDID, 'SessionId': sessionId }
        this.hubUrl = this.environmentService.getSignalrUrl('saraplus-hub');
        this.hubUrl += `?sessionId=${sessionId}`;
        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(this.hubUrl,
                {
                    headers: headers,
                    withCredentials: true,
                    transport: signalR.HttpTransportType.WebSockets,
                }) // SignalR hub URL
            .withAutomaticReconnect()
            .build();

    }

    startConnection(): Observable<void> {
        return new Observable<void>((observer) => {
            this.hubConnection
                .start()
                .then(() => {
                    console.log('Connection established with SignalR hub');
                    observer.next();
                    observer.complete();
                    this.startListening()
                })
                .catch((error) => {
                    console.error('Error connecting to SignalR hub:', error);
                    observer.error(error);
                });
        });
    }

    startListening() {
        this.hubConnection.on('ReceiveMessage', (encryptedMessage: string) => {

            const key = this.environmentService.sessionDecryptKey;
            const message: EncryptedSignalRModel = this.#encryptDecryptService.decrypt<EncryptedSignalRModel>(encryptedMessage, key, true);
            if (message && message.Type) {
                try {
                    if (message.Type === 'UserAction') {
                        this.#signalREventService.RaiseAlertEvent(message.Data as string);
                    }
                    else if (message.Type !== 'ConnectionEstablished') {
                        if (typeof message.Data === 'string') {
                            message.Data = JSON.parse(message.Data as string);
                        }

                        const signalRMessage: SignalRModel = {
                            data: message.Data,
                            type: message.Type
                        }
                        this.#signalREventService.RaiseEvent(signalRMessage);
                    }
                    else {
                        console.log("SignalR MessageType not handled", message);
                    }

                } catch (error) {
                    console.log('SignalR JSON parsing error', error, message);
                }

            }
        });

        this.hubConnection.on('SecureDataMessage', (message) => {
            console.log('SecureDataMessage', message);
            this.#signalREventService.RaiseEvent(message);
        });

        this.hubConnection.on('ReceiveEventB', (message) => {
            console.log('ReceiveEventB', message);
            this.#signalREventService.RaiseEvent(message);
        });
    }

    sendMessage(message: SignalRModel): void {
        this.hubConnection.invoke('SendMessage', message);
    }

    closeConnection(): Promise<void> {
        if (this.hubConnection) {
            return this.hubConnection.stop()
                .then(() => console.log('Connection stopped'))
                .catch(err => console.log('Error while stopping connection: ' + err));
        }
        return Promise.resolve();
    }
}

@Injectable({
    providedIn: 'root'
})
export class SignalREventService {
    private $eventSubscription = new BehaviorSubject<SignalRModel>({});
    private $events = this.$eventSubscription.asObservable();
    private $eventAlertSubscription = new BehaviorSubject<string | undefined>(undefined);
    private $alertEevents = this.$eventAlertSubscription.asObservable();

    get Events() {
        return this.$events;
    }

    get AlertEvents() {
        return this.$alertEevents;
    }

    RaiseEvent(model: SignalRModel) {
        this.$eventSubscription.next(model);
        //we want to send message only once
        this.$eventSubscription.next({});
    }

    RaiseAlertEvent(message: string) {
        this.$eventAlertSubscription.next(message);
        //we want to send message only once
        this.$eventAlertSubscription.next(undefined);
    }
}

export interface SignalRModel {
    type?: 'ValidateContactEmail' | 'ValidateContactMobilePhone' | 'CustomerInfo' | 'CustomerInfo-Cancel' | 'CreditInfo' | 'CreditInfo-Cancel'  | 'NCVCQuestionnaire' | 'NCVCQuestionnaire-Cancel' | 'TermsAndConditionInfo' | 'TermsAndConditionInfo-Cancel' | 'FPVInfo' | 'FPVInfo-Cancel' | 'OrderCheckList' | 'OrderCheckList-Cancel',
    data?: unknown
}

export interface SignalRTModel<T> extends SignalRModel {
    data?: T
}

export interface EncryptedSignalRModel {
    Type?: 'ValidateContactEmail' | 'ValidateContactMobilePhone' | 'CustomerInfo' | 'CustomerInfo-Cancel' | 'CreditInfo' | 'CreditInfo-Cancel' | 'NCVCQuestionnaire' | 'NCVCQuestionnaire-Cancel' | 'TermsAndConditionInfo'  | 'TermsAndConditionInfo-Cancel' | 'FPVInfo' | 'FPVInfo-Cancel' | 'OrderCheckList' | 'OrderCheckList-Cancel' | 'UserAction' | 'ConnectionEstablished',
    Data?: unknown
}

export interface EncryptedSignalRTModel<T> extends EncryptedSignalRModel {
    Data?: T
}