export enum OrderRoutePaths {
    ORDER_SETUP = 'setup',
    ALL_SERVICES_DOWN = 'all-services-down',
    SERVICE_INFORMATION = 'service-information-collection',
    BUNDLE_HOMEPAGE = 'bundle-homepage',
    CREDIT_CHECK = 'credit-check',
    ADVANCED_BILL_PAYMENT = 'advanced-bill-payment',
    ADDITIONAL_INTERNET_OFFERS = 'additional-internet-offers',
    ALTERNATE_SHIPPING = 'alternate-shipping-address',
    CUSTOMER_ACCOUNT_SETUP = 'customer-account-setup',
    PRODUCT_SUMMARY = 'product-summary',
    TERMS_AND_CONDITIONS = 'terms-and-conditions',
    ORDER_CHECKLIST = 'order-checklist',
    NCVC_QUESTIONNAIRE = 'ncvc-questionnaire',
    Account_REVIEW = 'account-review',
    CUSTOMER_ORDER_VERIFICATION = 'customer-order-verification',
    INSTALLATION_SCHEDULING = 'installation-scheduling',
    PAYMENT_INFORMATION = 'payment-information-entry',
    ORDER_SUMMARY = 'order-summary',
    ORDER_OPTIONS = 'order-options'
}

export const OrderRoutes = {
    ORDER_SETUP: `/order/${OrderRoutePaths.ORDER_SETUP}`,
    ALL_SERVICES_DOWN: `/order/${OrderRoutePaths.ALL_SERVICES_DOWN}`,
    SERVICE_INFORMATION: `/order/${OrderRoutePaths.SERVICE_INFORMATION}`,
    BUNDLE_HOMEPAGE: `/order/${OrderRoutePaths.BUNDLE_HOMEPAGE}`,
    CREDIT_CHECK: `/order/${OrderRoutePaths.CREDIT_CHECK}`,
    NCVC_QUESTIONNAIRE: `/order/${OrderRoutePaths.NCVC_QUESTIONNAIRE}`,
    Account_REVIEW: `/order/${OrderRoutePaths.Account_REVIEW}`,
    ADVANCED_BILL_PAYMENT: `/order/${OrderRoutePaths.ADVANCED_BILL_PAYMENT}`,
    ADDITIONAL_INTERNET_OFFERS: `/order/${OrderRoutePaths.ADDITIONAL_INTERNET_OFFERS}`,
    ALTERNATE_SHIPPING: `/order/${OrderRoutePaths.ALTERNATE_SHIPPING}`,
    CUSTOMER_ACCOUNT_SETUP: `/order/${OrderRoutePaths.CUSTOMER_ACCOUNT_SETUP}`,
    PRODUCT_SUMMARY: `/order/${OrderRoutePaths.PRODUCT_SUMMARY}`,
    TERMS_AND_CONDITIONS: `/order/${OrderRoutePaths.TERMS_AND_CONDITIONS}`,
    ORDER_CHECKLIST: `/order/${OrderRoutePaths.ORDER_CHECKLIST}`,
    CUSTOMER_ORDER_VERIFICATION: `/order/${OrderRoutePaths.CUSTOMER_ORDER_VERIFICATION}`,
    PAYMENT_INFORMATION: `/order/${OrderRoutePaths.PAYMENT_INFORMATION}`,
    ORDER_SUMMARY: `/order/${OrderRoutePaths.ORDER_SUMMARY}`,
    INSTALLATION_SCHEDULING: `/order/${OrderRoutePaths.INSTALLATION_SCHEDULING}`,
    ORDER_OPTIONS: `/order/${OrderRoutePaths.ORDER_OPTIONS}`
} as const