import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormValidationService {
  validateForm(form: FormGroup): boolean {
    if (form.invalid) {
      Object.values(form.controls).forEach(control => {
        control.markAsTouched();
      });
      return false;
    }
    return true;
  }

  resetFormErrors(form: FormGroup) {
    Object.values(form.controls).forEach(control => {
      control.setErrors(null);
    });
  }

  isFormValid(form: FormGroup): boolean {
    return form.valid;
  }

  markFormTouched(form: FormGroup) {
    Object.values(form.controls).forEach(control => {
      control.markAsTouched();
    });
  }
}