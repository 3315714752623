import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ZendeskService {
  
  constructor() { }

   toggleLiveChat() {
    window.zE('messenger', 'open');
  }

}
