import { Component, EventEmitter, Input, Output, Signal } from '@angular/core';
import { CalendarComponent } from '../calendar/calendar.component';
import { CommonModule } from '@angular/common';
import { PrimeNGModule } from '../../modules/primeng.module';

@Component({
  imports: [CommonModule,CalendarComponent, PrimeNGModule],
  selector: 'app-slot-booking',
  templateUrl: './slot-booking.component.html',
  styleUrls: ['./slot-booking.component.scss'],
  standalone: true
})
export class SlotBookingComponent {
  @Input() availableDates: Signal<any>; 
  @Output() slotSelected = new EventEmitter<any>();

  selectedDate: any;

  onDateSelected(date: any) {
    this.selectedDate = date;
  }

  onSlotSelected(slot: any) {
    this.slotSelected.emit(slot)
  }
}