import { Injectable, Signal, computed, inject, signal } from '@angular/core'
import { Select, Store } from '@ngxs/store'
import { EncryptDecryptService } from '../../../services/encrypt-decrypt.service'
import { EnvironmentService } from '../../../services/environment.service'
import { AddAvailableServicesAction, AddCreateOrderDetailsAction, AddCreditCheckDetailsAction, AddCustomerVerificationitemsAction, AddFormsAction, AddNCVCAction, AddAccountReviewAction, AddOrderQualificationDetailsAction, AddPaymentProcessDetailsAction, AddPreOrderAction, AddProductDetailsAction, AddStartOrderAction, AddSubmitOrderDetailsAction, OrderState, UpdateCustomerInfoAction } from './order.state'
import { Observable } from 'rxjs'
import { toSignal } from '@angular/core/rxjs-interop'
import { OrderDetails, PreOrderDetails, StartOrderDetails } from '../../../models/OrderDetails'
import { GenericStateService } from '../../generic-state/generic.state.service'

@Injectable({
  providedIn: 'root'
})
export class OrderStateService {

  #store: Store = inject(Store)
  #encryptDecryptService: EncryptDecryptService = inject(EncryptDecryptService)
  #environmentService: EnvironmentService = inject(EnvironmentService)
  #genericStateService: GenericStateService = inject(GenericStateService)

  @Select(OrderState.orderInfo) private orderSelector$: Observable<string>
  @Select(OrderState.preOrderInfo) private preOrderSelector$: Observable<string>
  @Select(OrderState.startOrderInfo) private startOrderSelector$: Observable<string>
  @Select(OrderState.availableServices) private availableServicesSelector$: Observable<string>
  @Select(OrderState.forms) private formsSelector$: Observable<string>
  @Select(OrderState.productDetails) private productDetailsSelector$: Observable<string>
  @Select(OrderState.customerVerificationItems) private customerVerificationitemsSelector$: Observable<string>
  @Select(OrderState.creditCheckDetails) private creditCheckDetailsSelector$: Observable<string>
  @Select(OrderState.orderQualificationDetails) private orderQualificationSelector$: Observable<string>
  @Select(OrderState.createOrderDetails) private createOrderSelector$: Observable<string>
  @Select(OrderState.submitOrderDetails) private submitOrderSelector$: Observable<string>
  @Select(OrderState.paymentProcessDetails) private paymentProcessSelector$: Observable<string>

  #orderSignal: Signal<string>
  #preOrderSignal: Signal<string>
  #startOrderSignal: Signal<string>
  #availabilityServicesSignal: Signal<string>
  #formsSignal: Signal<string>
  #productDetailsSignal: Signal<string>
  #customerVerificationitemsSignal: Signal<string>
  #creditCheckDetailsSignal: Signal<string>
  #orderQualificationSignal: Signal<string>
  #createOrderSignal: Signal<string>
  #submitOrderSignal: Signal<string>
  #paymentProcessSignal: Signal<string>

  constructor() {
    this.#orderSignal = toSignal(this.orderSelector$) as Signal<string>
    this.#preOrderSignal = toSignal(this.preOrderSelector$) as Signal<string>
    this.#startOrderSignal = toSignal(this.startOrderSelector$) as Signal<string>
    this.#availabilityServicesSignal = toSignal(this.availableServicesSelector$) as Signal<string>
    this.#formsSignal = toSignal(this.formsSelector$) as Signal<string>
    this.#productDetailsSignal = toSignal(this.productDetailsSelector$) as Signal<string>
    this.#customerVerificationitemsSignal = toSignal(this.customerVerificationitemsSelector$) as Signal<string>
    this.#creditCheckDetailsSignal = toSignal(this.creditCheckDetailsSelector$) as Signal<string>
    this.#orderQualificationSignal = toSignal(this.orderQualificationSelector$) as Signal<string>
    this.#createOrderSignal = toSignal(this.createOrderSelector$) as Signal<string>
    this.#submitOrderSignal = toSignal(this.submitOrderSelector$) as Signal<string>
    this.#paymentProcessSignal = toSignal(this.paymentProcessSelector$) as Signal<string>
  }

  setPreOrder(preOrder: PreOrderDetails) {
    this.#store.dispatch(new AddPreOrderAction(preOrder))
  }

  setStartOrder(startOrder: StartOrderDetails) {
    this.#store.dispatch(new AddStartOrderAction(startOrder))
  }

  setAvailableServices(availableServices: any) {
    this.#store.dispatch(new AddAvailableServicesAction(availableServices))
  }

  setForms(forms: any) {
    this.#store.dispatch(new AddFormsAction(forms))
  }

  setProductDetails(productDetails: any) {
    this.#store.dispatch(new AddProductDetailsAction(productDetails))
  }

  setCustomerVerificationItems(customerVerificationItems: any) {
    this.#store.dispatch(new AddCustomerVerificationitemsAction(customerVerificationItems))
  }

  setCreditCheck(creditCheckDetails: any) {
    this.#store.dispatch(new AddCreditCheckDetailsAction(creditCheckDetails))
  }

  setNCVC(creditCheckDetails: any) {
    this.#store.dispatch(new AddNCVCAction(creditCheckDetails))
  }

  setAccountReview(accountReview: any) {
    this.#store.dispatch(new AddAccountReviewAction(accountReview))
  }

  setOrderQualification(orderQualificationDetails: any) {
    this.#store.dispatch(new AddOrderQualificationDetailsAction(orderQualificationDetails))
  }

  setCreateOrder(createOrderDetails: any) {
    this.#store.dispatch(new AddCreateOrderDetailsAction(createOrderDetails))
  }

  setSubmitOrder(submitOrderDetails: any) {
    this.#store.dispatch(new AddSubmitOrderDetailsAction(submitOrderDetails))
  }

  setPaymentResponse(paymentResponseDetails: any) {
    this.#store.dispatch(new AddPaymentProcessDetailsAction(paymentResponseDetails))
  }

  updateCustomerInfo(customerUpdates: Partial<any>) {
    const currentServices = this.#availabilityServicesSignal()
    if (currentServices) {
      this.#store.dispatch(new UpdateCustomerInfoAction(customerUpdates, currentServices))
    }
  }

  getPreOrder = computed(() => {
    return this.#encryptDecryptService.decrypt<any>(this.#preOrderSignal(), this.#environmentService.sessionDecryptKey)
  })

  getStartOrder = computed(() => {
    return this.#encryptDecryptService.decrypt<StartOrderDetails>(this.#startOrderSignal(), this.#environmentService.sessionDecryptKey)
  })

  getOrder = computed(() => {
    return this.#encryptDecryptService.decrypt<OrderDetails>(this.#orderSignal(), this.#environmentService.sessionDecryptKey)
  })

  getAvailableServices = computed(() => {
    return this.#encryptDecryptService.decrypt<any>(this.#availabilityServicesSignal(), this.#environmentService.sessionDecryptKey)
  })

  getForms = computed(() => {
    return this.#encryptDecryptService.decrypt<any>(this.#formsSignal(), this.#environmentService.sessionDecryptKey)
  })

  getProductDetails = computed(() => {
    return this.#encryptDecryptService.decrypt<any>(this.#productDetailsSignal(), this.#environmentService.sessionDecryptKey)
  })

  getCustomerVerificationItems = computed(() => {
    return this.#encryptDecryptService.decrypt<any>(this.#customerVerificationitemsSignal(), this.#environmentService.sessionDecryptKey)
  })

  getCreditCheckDetails = computed(() => {
    return this.#encryptDecryptService.decrypt<any>(this.#creditCheckDetailsSignal(), this.#environmentService.sessionDecryptKey)
  })

  getOrderQualificationDetails = computed(() => {
    return this.#encryptDecryptService.decrypt<any>(this.#orderQualificationSignal(), this.#environmentService.sessionDecryptKey)
  })

  getCreateOrderDetails = computed(() => {
    return this.#encryptDecryptService.decrypt<any>(this.#createOrderSignal(), this.#environmentService.sessionDecryptKey)
  })

  getSubmitOrderDetails = computed(() => {
    return this.#encryptDecryptService.decrypt<any>(this.#submitOrderSignal(), this.#environmentService.sessionDecryptKey)
  })

  getPaymentProcessDetails = computed(() => {
    return this.#encryptDecryptService.decrypt<any>(this.#paymentProcessSignal(), this.#environmentService.sessionDecryptKey)
  })

  advancedBillPaymentRequired = computed(() => {
    const resp = this.getCreditCheckDetails()
    return resp?.CreditCustomizations &&
      resp.CreditCustomizations.Customization &&
      resp.CreditCustomizations.Customization.Choices &&
      resp.CreditCustomizations.Customization.Choices.Choice &&
      resp.CreditCustomizations.Customization.Choices.Choice.Name === 'Internet Advanced Bill Payment'
  })

  advancedBillPaymentAccepted = computed(() => {
    return this.getForms()?.advancedBillPaymentForm?.advancedBillPayment
  })

  todaysChargesCategoryTotal = signal(undefined)

  resetProductSelection() {
    this.setProductDetails(null)
    this.setForms({ ...this.getForms(), advancedBillPaymentForm: null })
  }

  reset() {
    this.setPreOrder(null)
    this.setStartOrder(null)
    this.setAvailableServices(null)
    this.setProductDetails(null)
    this.setCustomerVerificationItems(null)
    this.setCreditCheck(null)
    this.setOrderQualification(null)
    this.setCreateOrder(null)
    this.setSubmitOrder(null)
    this.setPaymentResponse(null)
    this.setForms(null)
    this.#genericStateService.removeAllItems()
  }
}
